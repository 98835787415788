import { useState } from "react";
import { Alert, Row, Col, Form, Input, Button, message } from "antd";
import {
  BankOutlined,
  MailOutlined,
  // PhoneOutlined,
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import useContacts from "../../hooks/useContacts";
import "./index.scss";

const { TextArea } = Input;

const ContactUs = () => {
  const { sendMessage } = useContacts();
  const [error, setError] = useState({ status: false, message: null });
  const [form] = Form.useForm();
  const initialValues = {
    email: null,
    firstName: null,
    lastName: null,
    message: null,
    phoneNumber: null,
  };

  const onFinish = (val) => {
    const finalMessage = {
      email: val.email ? val.email.trim() : null,
      firstName: val.firstName ? val.firstName.trim() : null,
      lastName: val.lastName ? val.lastName.trim() : null,
      message: val.message ? val.message.trim() : null,
      phoneNumber: val.phoneNumber ? val.phoneNumber.trim() : null,
      status: 0,
    };
    if (
      !finalMessage.firstName ||
      !finalMessage.lastName ||
      !finalMessage.message
    ) {
      message.warning("Please fill all the required fields!");
    } else {
      sendMessage(finalMessage)
        .then(() => {
          form.resetFields();
        })
        .catch((err) => {
          if (err.message === "Query Open") {
            setError({
              status: true,
              message: "You have an open query. We will contact you soon.",
            });
          }
        });
    }
  };

  const onFinishFailed = () => {};

  return (
    <div className="app-contact page-container vertical-scroll flex flex--fit">
      <div className="flex flex--fit relative">
        <div className="p-section primary flex flex--fit"></div>
        <div className="p-section secondary flex flex--fit flex--responsive"></div>
        <div className="page-content flex">
          <div className="content-overlay flex flex--fit flex--responsive"></div>
          <div className="content-container relative flex flex--fit flex--row-responsive">
            <div className="content primary flex flex--column flex--space-between">
              <div>
                <h2
                  className="font--section-sub-heading"
                  style={{ marginBottom: 20 }}
                >
                  Contact Info
                </h2>
                <div className="content-item font--sub-section-content flex flex--align-center">
                  <BankOutlined style={{ fontSize: 26 }} />
                  <span style={{ marginLeft: 20 }}>
                    AlphaZBrains Technologies, 18th Main Rd, Sector 3, HSR
                    Layout, Bengaluru, Karnataka 560102
                  </span>
                </div>
                <div className="content-item font--sub-section-content flex--align-center">
                  <MailOutlined style={{ fontSize: 26 }} />
                  <span style={{ marginLeft: 20 }}>
                    contact@alphazbrainz.com
                  </span>
                </div>
                {/* <div className="content-item font--sub-section-content flex--align-center">
                  <PhoneOutlined style={{ fontSize: 26 }} />
                  <span style={{ marginLeft: 20 }}>+91 9035717445</span>
                </div> */}
              </div>
              <div className="social-media-panel flex flex--center">
                <span>
                  <a href="mailto:contact@alphazbrainz.com">
                    <MailOutlined />
                  </a>
                  <FacebookOutlined />
                  <InstagramOutlined />
                  <LinkedinOutlined />
                  <TwitterOutlined />
                </span>
              </div>
            </div>
            <div className="content secondary flex flex--column">
              {error.status && (
                <Alert
                  message={error.message}
                  type="info"
                  showIcon
                  style={{ marginBottom: 10 }}
                />
              )}
              <h2
                className="font--section-sub-heading invert"
                style={{ marginBottom: 20 }}
              >
                Send a Message
              </h2>
              <Form
                form={form}
                className="contact-form"
                layout="vertical"
                name="contact"
                initialValues={initialValues}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label="First name"
                      name="firstName"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your first name!",
                        },
                      ]}
                    >
                      <Input maxLength={50} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      label="Last Name"
                      name="lastName"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your last name!",
                        },
                      ]}
                    >
                      <Input maxLength={50} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={12}>
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        { type: "email" },
                        {
                          required: true,
                          message: "Please enter your email!",
                        },
                      ]}
                    >
                      <Input maxLength={50} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Phone Number" name="phoneNumber">
                      <Input maxLength={15} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Form.Item
                      label="Message"
                      name="message"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your message!",
                        },
                      ]}
                    >
                      <TextArea
                        maxLength={1000}
                        rows={4}
                        placeholder="Write your message here..."
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24} className="flex flex--justify-end">
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginTop: 30 }}
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
