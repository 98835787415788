export const ROUTES = {
  HOME: "/",
  ABOUT_US: "/about-us",
  SERVICES: "/services",
  CONTACT_US: "/contact-us",
  NOT_FOUND: "*",
};

export const APP_NAME = "AlphaZBrains";
export const CURRENT_YEAR = "2023";

export const NOTIFICAION_TYPE = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

export const DB_COLLECTIONS = {
  MESSAGES: "messages",
};
