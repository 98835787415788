import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  user: undefined,
  sidebar: "dashboard",
  notification: null,
};

const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.isLoading = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setSidebar: (state, action) => {
      state.sidebar = action.payload;
    },
    setNotification: (state, action) => {
      state.notification = action.payload;
    },
  },
});

// Actions
export const { setLoader, setUser, setSidebar, setNotification } =
  globalSlice.actions;

// Selectors
export const loaderSelector = (state) => state.global.isLoading;
export const userSelector = (state) => state.global.user;
export const sidebarSelector = (state) => state.global.sidebar;
export const notificationSelector = (state) => state.global.notification;

export default globalSlice.reducer;
