import { Routes, Route } from "react-router-dom";
import Landing from "./routes/landing";
import Home from "./routes/home";
import AboutUs from "./routes/aboutUs";
import ContactUs from "./routes/contactUs";
import Services from "./routes/services";
import NotFound from "./routes/notFound";
import Notification from "./components/notification";
import Loader from "./components/loader";
import { ROUTES } from "./constants";
import "antd/dist/reset.css";
import "./styles/global.scss";

const App = () => {
  return (
    <>
      <Loader />
      <Notification />
      <Routes>
        <Route element={<Landing />}>
          <Route path={ROUTES.HOME} element={<Home />} />
          <Route path={ROUTES.DASHBOARD} element={<Home />} />
          <Route path={ROUTES.ABOUT_US} element={<AboutUs />} />
          <Route path={ROUTES.CONTACT_US} element={<ContactUs />} />
          <Route path={ROUTES.SERVICES} element={<Services />} />
          <Route path={ROUTES.NOT_FOUND} element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
