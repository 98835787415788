import React from "react";
import { useNavigate } from "react-router-dom";
import { Result } from "antd";
import Button from "../../components/button";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div
      className="full-height flex flex--fit flex--center"
      style={{ paddingTop: 64 }}
    >
      <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={
          <Button type="primary" onClick={() => navigate("/")}>
            Back Home
          </Button>
        }
      />
    </div>
  );
};

export default NotFound;
