import { useDispatch, useSelector } from "react-redux";
import {
  notificationSelector,
  setNotification as setGlobalNotification,
} from "../store/reducers/global.reducer";

const useNotification = () => {
  const dispatch = useDispatch();
  const notification = useSelector(notificationSelector);

  /**
   * @description Set Notification
   */
  const setNotification = (notificationData) => {
    dispatch(setGlobalNotification(notificationData));
  };

  return { notification, setNotification };
};

export default useNotification;
