import aboutUs1 from "../../assets/images/about-us-1.jpg";
import aboutUs2 from "../../assets/images/about-us-2.jpg";
import aboutUs3 from "../../assets/images/about-us-3.jpg";
import "./index.scss";

const AboutUs = () => {
  return (
    <div className="app-about page-container vertical-scroll ">
      <div className="bg-container flex flex--fit relative" />
      <div className="section-container flex flex--row-responsive">
        <img className="section-image" src={aboutUs1} alt="About Us" />
        <div className="section-content">
          <div className="font--section-heading">Welcome to AlphaZBrains</div>
          <p className="font--section-content">
            Welcome to Our IT Company We are proud to be a leading provider of
            IT services for small and medium-sized businesses throughout the
            region. Our IT professionals have decades of experience in the
            industry and are dedicated to helping keep your company’s technology
            running smoothly.
          </p>
          <p className="font--section-content">
            We understand the importance of your business’s IT infrastructure,
            which is why we strive to offer the best service possible. With our
            personalized approach and superior technology, we are confident we
            can help you optimize your IT investments.
          </p>
          <p className="font--section-content">
            Our experienced team of professionals is here to help you create an
            effective, user-friendly website or digital marketing campaign that
            will help you reach your goals. We provide a wide range of services,
            including website development, website hosting, e-commerce site
            development, design solutions, consultation, and digital marketing
            services.
          </p>
        </div>
      </div>
      <div className="section-breaker font--section-sub-heading invert">
        <span className="quote">"</span>
        <span>
          Our mission is to provide innovative solutions that exceed customer
          expectations.
        </span>
        <span className="quote">"</span>
      </div>
      <div className="section-container flex flex--row-responsive">
        <img className="section-image" src={aboutUs2} alt="About Us" />
        <div className="section-content">
          <div className="font--section-heading">What We Do</div>
          <p className="font--section-content">
            Our team of experienced IT professionals services a wide range of
            technology needs for businesses of all sizes. We can upgrade your
            existing IT systems with new hardware and software, provide ongoing
            maintenance and troubleshooting to keep your systems running
            smoothly, and develop custom-built applications tailored to your
            organization’s needs.
          </p>

          <p className="font--section-content">
            We understand that every business is different, so we take the time
            to listen to your needs and develop a custom strategy tailored to
            your individual business goals. Our team of experts can help you
            create an effective online experience that will drive leads and
            increase conversions. We also offer a variety of digital marketing
            services that can help you reach more customers and increase brand
            awareness. With our comprehensive approach, we can provide the
            solutions you need to take your business to the next level.
          </p>
        </div>
      </div>
      <div className="section-breaker font--section-sub-heading invert">
        <span className="quote">"</span>
        <span>
          We strive to be the best in the industry, providing quality products
          and services that make a difference in people's lives.
        </span>
        <span className="quote">"</span>
      </div>
      <div className="section-container flex flex--row-responsive">
        <img className="section-image" src={aboutUs3} alt="About Us" />
        <div className="section-content">
          <div className="font--section-heading">Why Choose Us?</div>
          <p className="font--section-content">
            With years of experience in the industry, our IT professionals are
            the best in the business. We can provide you with cost-effective and
            tailored solutions to any of your IT needs and our customer service
            is second to none. We are also constantly updating our offerings to
            make sure our clients have access to the latest and greatest
            technologies.
          </p>

          <p className="font--section-content">
            If you’re looking for fast, dependable, and comprehensive IT
            services, look no further than AlphaZBrains. Contact us today for
            more information or to get started.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
