import { Menu } from "antd";
import {
  UserSwitchOutlined,
  GiftOutlined,
  ContactsOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants";
import "./index.scss";

const items = [
  {
    label: "About Us",
    key: "ABOUT_US",
    icon: <UserSwitchOutlined />,
  },
  {
    label: "Services",
    key: "SERVICES",
    icon: <GiftOutlined />,
  },
  {
    label: "Contact Us",
    key: "CONTACT_US",
    icon: <ContactsOutlined />,
  },
];

const AppMenu = ({ className = "", selectedMenu, setSelectedMenu }) => {
  const navigate = useNavigate();

  /**
   * @description Handle menu click
   * @param { string } key
   */
  const handleMenuClick = (key) => {
    setSelectedMenu(key);
    navigate(ROUTES[key]);
  };

  return (
    <Menu
      className={`app-menu ${className}`}
      onClick={({ key }) => handleMenuClick(key)}
      selectedKeys={[selectedMenu]}
      mode="horizontal"
      items={items}
    />
  );
};

export default AppMenu;
