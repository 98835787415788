import { Spin } from "antd";
import "./index.scss";

const Loader = ({ isLoading = false }) => {
  return isLoading ? (
    <div className="app-loader flex flex--center">
      <Spin size="large" />
    </div>
  ) : null;
};

export default Loader;
