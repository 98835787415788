import "./index.scss";

const Home = () => {
  return (
    <div className="app-home vertical-scroll flex flex--fit flex--column">
      <div className="flex flex--row-responsive page-layout">
        <div className=" flex flex--fit flex--column">
          <div className="text-wrapper">
            <h1 className="font--main-heading">
              Revolutionizing Technology Through Aspiration
            </h1>
            <p className="font--section-content">
              At AlphaZBrains, we believe that technology has the potential to
              transform the world and unlock amazing opportunities for all. We
              believe that technology can help people and organizations lead
              better and more successful lives. We strive to be a technology
              partner that can help you unleash the potential of the world's
              leading technologies. Our mission is to empower people with the
              latest, most reliable technology solutions, enabling them to
              maximize their potential and realize their aspirations. We design
              and develop technology solutions that are intuitive and easy to
              use, so businesses and individuals alike have the tools and
              resources to fulfill their goals.
            </p>
          </div>
        </div>
        <div className=" flex flex--fit">
          <div>
            <img
              className="title-img stretch"
              src="	https://mobirise.com/extensions/goalm5/itsolution/assets/images/15.jpg"
              alt="Theme"
            />
          </div>
        </div>
      </div>
      <section className="secondary-section page-layout">
        <div className="section-container">
          <h1 className="font--main-heading">Our Featured Services</h1>
          <div className="section-body flex flex--row-responsive">
            <div className="feature-box flex flex--fit">
              <div className="font--section-heading invert">
                Website Development
              </div>
              <p className="font--section-content invert">
                Our experienced developers are here to create a personalized
                website solution tailored to your business’s needs and goals. We
                provide a comprehensive range of services such as custom web
                design, content management systems, eCommerce development, and
                more.
              </p>
            </div>
            <div className="feature-box flex flex--fit">
              <div className="font--section-heading invert">
                Website Hosting
              </div>
              <p className="font--section-content invert">
                Our company provides high-quality website hosting depending on
                our client's needs. We also offer a cost-effective website
                hosting solution to meet your budget and business needs.
              </p>
            </div>
            <div className="feature-box flex flex--fit">
              <div className="font--section-heading invert">
                Digital Marketing
              </div>
              <p className="font--section-content invert">
                Our company provides outstanding digital marketing services to
                meet all our customer's unique needs. We specialize in creating
                highly effective marketing strategies to drive more business and
                generate more leads.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="secondary-section heighlight">
        <div className="section-container">
          <div className="section-header">
            <h1 className="font--main-heading">Services & Support</h1>
          </div>
          <div className="section-body flex flex--row-responsive">
            <div className="section-box flex flex--fit">
              <div className="data-card">
                <h2 className="font--section-heading">Consulting</h2>
                <p className="font--section-content dark">
                  Our company provides competent, professional consulting
                  services to help our clients reach their goals. With decades
                  of experience and a commitment to excellence, we will provide
                  you with the best consulting solutions to fit your needs and
                  exceed your expectations.
                </p>
              </div>
            </div>
            <div className="section-box flex flex--fit">
              <div className="data-card">
                <h2 className="font--section-heading">Project Planning</h2>
                <p className="font--section-content dark">
                  Our company provides a comprehensive range of project planning
                  services that include defining objectives, estimating time and
                  cost, and creating a detailed pathway to success. Our team of
                  experienced professionals utilizes cutting-edge technology and
                  advanced methodologies in order to streamline the project
                  planning process and increase cost efficiency.
                </p>
              </div>
            </div>
            <div className="section-box flex flex--fit">
              <div className="data-card">
                <h2 className="font--section-heading">Design Solutions</h2>
                <p className="font--section-content dark">
                  Our company offers design solutions to make your business
                  stand out from the rest. Our design solutions will create a
                  unique, impactful product that showcases your brand perfectly.
                </p>
              </div>
            </div>
          </div>
          <div className="section-body flex flex--row-responsive">
            <div className="section-box flex flex--fit">
              <div className="data-card">
                <h2 className="font--section-heading">Branding</h2>
                <p className="font--section-content dark">
                  Our company helps businesses create a strong and successful
                  brand identity to stand out in the market. We provide tailored
                  brand development strategies to help clients grow their
                  businesses and create a memorable, impactful presence.
                </p>
              </div>
            </div>
            <div className="section-box flex flex--fit">
              <div className="data-card">
                <h2 className="font--section-heading">E-commerce</h2>
                <p className="font--section-content dark">
                  Our company specializes in e-commerce solutions for businesses
                  of all sizes, offering premium features such as easy-to-use
                  product management, optimized checkout flows, and secure
                  checkout. We are dedicated to providing the best e-commerce
                  solutions to make your business succeed.
                </p>
              </div>
            </div>
            <div className="section-box flex flex--fit">
              <div className="data-card">
                <h2 className="font--section-heading">Market Ideas</h2>
                <p className="font--section-content dark">
                  Our company can help create a comprehensive market strategy
                  tailored to your unique business, to help you effectively
                  reach your target customers. From ad campaigns to social media
                  marketing and beyond, we can help you generate interest and
                  increase the visibility of your product or service.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
