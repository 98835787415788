import { useNavigate } from "react-router-dom";
import { APP_NAME, CURRENT_YEAR, ROUTES } from "../../constants";

import "./index.scss";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="app-footer flex flex--column">
      <div className="flex flex--row-responsive">
        <div className="footer-section flex flex--column flex--fit">
          <h4 className="font--section-sub-heading">AlphaZBrains</h4>
          <span
            className="font--sub-section-content cursor-pointer"
            onClick={() => navigate(ROUTES.ABOUT_US)}
          >
            About Us
          </span>
          <span
            className="font--sub-section-content cursor-pointer"
            onClick={() => navigate(ROUTES.SERVICES)}
          >
            Services
          </span>
          <span
            className="font--sub-section-content cursor-pointer"
            onClick={() => navigate(ROUTES.CONTACT_US)}
          >
            Contact Us
          </span>
        </div>
        <div className="footer-section flex flex--column flex--fit">
          <h4
            className="font--section-sub-heading  cursor-pointer"
            onClick={() => navigate(`${ROUTES.SERVICES}`)}
          >
            Services
          </h4>
          <span
            className="font--sub-section-content  cursor-pointer"
            onClick={() => navigate(`${ROUTES.SERVICES}`)}
          >
            Website Development
          </span>
          <span
            className="font--sub-section-content cursor-pointer"
            onClick={() => navigate(`${ROUTES.SERVICES}`)}
          >
            Website Hosting
          </span>
          <span
            className="font--sub-section-content  cursor-pointer"
            onClick={() => navigate(`${ROUTES.SERVICES}`)}
          >
            Consulting
          </span>
          <span
            className="font--sub-section-content cursor-pointer"
            onClick={() => navigate(`${ROUTES.SERVICES}`)}
          >
            Digital Marketing
          </span>
        </div>
        <div className="footer-section flex flex--column flex--fit">
          <h4 className="font--section-sub-heading">Connect with Us</h4>
          <a href="mailto:contact@alphazbrainz.com">
            <span className="font--sub-section-content">Email Us</span>
          </a>
          <span className="font--sub-section-content">Facebook</span>
          <span className="font--sub-section-content">Instagram</span>
          <span className="font--sub-section-content">Linkedin</span>
          <span className="font--sub-section-content">Twitter</span>
        </div>
      </div>
      <div className="flex flex--center copyright-text font--sub-section-content invert">
        <p>{`© Copyright ${CURRENT_YEAR} ${APP_NAME}. All Rights Reserved`}</p>
      </div>
    </div>
  );
};

export default Footer;
