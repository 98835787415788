import { useSelector, useDispatch } from "react-redux";
import {
  loaderSelector,
  setLoader as setGlobalLoader,
} from "../store/reducers/global.reducer";

const useLoader = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(loaderSelector);
  const loader = isLoading;

  /**
   * @function setLoader
   * @param {boolean} loaderStatus: Loader status
   * @description Show/hide loader
   */
  const setLoader = (loaderStatus) => {
    dispatch(setGlobalLoader(loaderStatus));
  };

  return {
    loader,
    isLoading,
    setLoader,
  };
};

export default useLoader;
