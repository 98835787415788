import services1 from "../../assets/images/services-1.jpg";
import services2 from "../../assets/images/services-2.jpg";
import services3 from "../../assets/images/services-3.jpg";
import services4 from "../../assets/images/services-4.jpg";
import services5 from "../../assets/images/services-5.jpg";
import "./index.scss";

const Services = () => {
  return (
    <div className="app-services page-container vertical-scroll ">
      <div className="bg-container flex flex--fit relative" />

      <div className="section-container flex flex--row-responsive">
        <img className="section-image" src={services3} alt="About Us" />
        <div className="section-content">
          <div className="font--section-heading">Website Development</div>
          <p className="font--section-content">
            Website development is the process of creating a website that meets
            the needs of a business or organization. It involves the creation of
            the website’s look and feels, the coding that powers the website,
            and the content that makes it useful and engaging. The development
            process starts with understanding the needs of the business or
            organization and the purpose of the website. From there, the website
            can be designed and built to meet those needs. This can involve
            creating website navigation and content, integrating web
            technologies, and ensuring the website is secure and up-to-date.
          </p>
          <p className="font--section-content">
            Website development also includes making sure the website can be
            accessed from any type of device, such as a desktop or mobile phone.
            This is called “responsive design.” Responsive design ensures that
            the website looks great on any device, and makes it easy for users
            to find the information they need.
          </p>
        </div>
      </div>

      <div className="section-container flex flex--row-responsive">
        <img className="section-image" src={services1} alt="About Us" />
        <div className="section-content">
          <div className="font--section-heading">Mobile App Development</div>
          <p className="font--section-content">
            Mobile app development is the process of creating software
            applications that run on mobile devices such as smartphones and
            tablets. It involves designing, coding, testing, and launching the
            app into the market. Mobile app development has become an
            increasingly popular and lucrative profession. Mobile apps can serve
            a variety of purposes, from providing entertainment to helping
            people stay organized and productive. They can also be used to
            access information and services, such as banking, health care, and
            shopping.
          </p>
          <p className="font--section-content">
            As the demand for mobile apps continues to grow, so does the need
            for experienced and qualified mobile app developers.
          </p>
        </div>
      </div>

      <div className="section-container flex flex--row-responsive">
        <img className="section-image" src={services5} alt="About Us" />
        <div className="section-content">
          <div className="font--section-heading">Website Hosting</div>
          <p className="font--section-content">
            Website hosting is an essential part of having a website. It is the
            process of storing your website’s content on a server so that it can
            be accessed by visitors around the world. Without website hosting,
            your website would not be available for viewing. When you sign up
            for website hosting, you will receive a certain amount of web space
            and a control panel that will allow you to manage your website. You
            will also receive a domain name, which is the address that users
            will type into their browser to view your website.
          </p>
          <p className="font--section-content">
            When selecting a web hosting provider, it is important to consider
            factors such as bandwidth, disk space, uptime, customer support, and
            pricing. You should also consider the type of hosting that is best
            suited to your website, such as shared hosting, VPS hosting, or
            dedicated hosting.
          </p>
        </div>
      </div>

      <div className="section-container flex flex--row-responsive">
        <img className="section-image" src={services4} alt="About Us" />
        <div className="section-content">
          <div className="font--section-heading">Consulting</div>
          <p className="font--section-content">
            Software consulting refers to the practice of providing expert
            advice and services related to software development and
            implementation. It involves helping organizations identify the best
            software solutions to meet their goals and objectives while taking
            into account budget constraints, technical complexity, and other
            factors. Software consulting is typically provided by a team of
            software experts who have experience in the development,
            implementation, and maintenance of software systems. These experts
            can offer guidance and advice on the most effective software
            solutions to meet an organization’s needs. They can also help an
            organization develop a software strategy that will enable them to
            better manage their software investments and maximize the return on
            their software investments.
          </p>
        </div>
      </div>

      <div className="section-container flex flex--row-responsive">
        <img className="section-image" src={services2} alt="About Us" />
        <div className="section-content">
          <div className="font--section-heading">Digital Marketing</div>
          <p className="font--section-content">
            Digital marketing is the process of using digital technologies and
            channels to promote and market products and services. It is an
            essential component of modern business, as it allows companies to
            reach a wider audience and interact with potential customers in
            real-time. Digital marketing encompasses a wide variety of
            activities, including search engine optimization (SEO), content
            marketing, social media marketing, email marketing, pay-per-click
            (PPC) advertising, and more.
          </p>

          <p className="font--section-content">
            Digital marketing offers many benefits to businesses of all sizes.
            It is an affordable way to reach a large audience and can be
            tailored to meet the specific needs of your business. Digital
            marketing also provides the opportunity to build relationships with
            customers, track results, and measure return on investment (ROI)
          </p>
        </div>
      </div>
    </div>
  );
};

export default Services;
