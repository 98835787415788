import dayjs from "dayjs";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
  updateDoc,
  query,
  where,
  orderBy,
  limit,
  getCountFromServer,
} from "firebase/firestore";
import useLoader from "./useLoader";
import { db } from "../services/firebase";

const useDao = () => {
  const { setLoader } = useLoader();

  /**
   * @description Add Document
   * @param { string } collectionName Collection name
   * @param { unknown } data Data
   * @param { boolean } globalLoader Global loader status
   */
  const addDocument = (collectionName, data, globalLoader = true) => {
    globalLoader && setLoader(true);

    return addDoc(collection(db, collectionName), {
      ...data,
      createdOn: dayjs().toISOString(true),
    }).finally(() => {
      globalLoader && setLoader(false);
    });
  };

  /**
   * @description Get Collection
   * @param { string } collectionName Collection
   * @param { unknown } filter  Query filter
   * @param { unknown } sort  Query sort
   * @param { boolean } globalLoader Global loader status
   */
  const getCollection = (
    collectionName,
    filter,
    sort,
    limitVal,
    globalLoader = true
  ) => {
    const queryConstraints = getQueryConstraints(filter, sort, limitVal);
    const q = query(
      collection(db, collectionName),
      ...queryConstraints.filter,
      ...queryConstraints.sort,
      ...queryConstraints.limit
    );

    globalLoader && setLoader(true);

    return getDocs(q)
      .then((querySnapshot) => {
        if (querySnapshot) {
          return (querySnapshot.docs || []).map((doc) => {
            return doc.data();
          });
        } else {
          return [];
        }
      })
      .finally(() => {
        globalLoader && setLoader(false);
      });
  };

  /**
   * @description Get Document
   * @param { string } collectionName Collection
   * @param { string } documentName  Document
   * @param { unknown } filter  Query filter
   * @param { boolean } globalLoader Global loader status
   */
  const getDocument = (
    collectionName,
    documentName,
    filter,
    globalLoader = true
  ) => {
    globalLoader && setLoader(true);

    return getDoc(
      query(doc(db, collectionName, documentName), getFilter(filter))
    )
      .then((querySnapshot) => {
        globalLoader && setLoader(false);

        if (querySnapshot.exists()) {
          return querySnapshot.data();
        } else {
          return undefined;
        }
      })
      .finally(() => {
        globalLoader && setLoader(false);
      });
  };

  /**
   * @description Set Document
   * @param { unknown } collectionName Collection name
   * @param { string } documentName Document name
   * @param { unknown } data Data
   * @param { boolean } globalLoader Global loader status
   */
  const setDocument = (
    collectionName,
    documentName,
    data,
    globalLoader = true
  ) => {
    globalLoader && setLoader(true);

    return setDoc(doc(db, collectionName, documentName), {
      ...data,
      createdOn: dayjs().toISOString(true),
    }).finally(() => {
      globalLoader && setLoader(false);
    });
  };

  /**
   * @description Update Document
   * @param { unknown } collectionName Collection name
   * @param { string } documentName Document name
   * @param { unknown } data Data
   * @param { boolean } globalLoader Global loader status
   */
  const updateDocument = (
    collectionName,
    documentName,
    data,
    globalLoader = true
  ) => {
    globalLoader && setLoader(true);

    return updateDoc(doc(db, collectionName, documentName), {
      ...data,
      modifiedOn: dayjs().toISOString(true),
    }).finally(() => {
      globalLoader && setLoader(false);
    });
  };

  /**
   * @description Get Document
   * @param { string } collectionName Collection
   * @param { string } documentName  Document
   * @param { boolean } globalLoader Global loader status
   */
  const deleteDocument = (
    collectionName,
    documentName,
    globalLoader = true
  ) => {
    globalLoader && setLoader(true);

    return deleteDoc(doc(db, collectionName, documentName)).finally(() => {
      globalLoader && setLoader(false);
    });
  };

  /**
   * @description Get Count
   * @param { string } collectionName Collection
   * @param { unknown } filter  Query filter
   * @param { boolean } globalLoader Global loader status
   */
  const getCount = (collectionName, filter, globalLoader = true) => {
    globalLoader && setLoader(true);

    const coll = collection(db, collectionName);
    const q = query(coll, getFilter(filter));
    return getCountFromServer(q)
      .then((snapshot) => {
        return snapshot.data().count;
      })
      .finally(() => {
        globalLoader && setLoader(false);
      });
  };

  /**
   * @description: Get Query filter
   * @param { unknown } filter: Filter options
   */
  const getFilter = (filter) => {
    return filter
      ? where(filter.field, filter.operator, filter.value)
      : undefined;
  };

  /**
   * @description: Get query constraints
   * @param { unknown } filter: Filter
   * @param { unknown } order: Order
   */
  const getQueryConstraints = (filter = [], sort, limitVal) => {
    const filterArray = Array.isArray(filter) ? filter : [filter];
    const constraints = {
      filter: [],
      sort: [],
      limit: [],
    };

    filterArray.forEach((f) => {
      constraints.filter.push(where(f.field, f.operator, f.value));
    });

    if (sort) {
      constraints.sort.push(orderBy(sort.field, sort.value));
    }

    if (limitVal) {
      constraints.sort.push(limit(limitVal));
    }

    return constraints;
  };

  return {
    addDocument,
    getCollection,
    getDocument,
    setDocument,
    updateDocument,
    deleteDocument,
    getCount,
  };
};

export default useDao;
