import { useEffect } from "react";
import { notification as defaultNotification } from "antd";
import useNotification from "../../hooks/useNotification";

const Notification = () => {
  const [api, contextHolder] = defaultNotification.useNotification();
  const { notification, setNotification } = useNotification();

  useEffect(() => {
    if (notification && (notification.message || notification.description)) {
      api[notification.type ?? "info"]({
        message: notification.message,
        description: notification.description,
      });
    }

    setNotification(null);
  }, [notification]);
  return <>{contextHolder}</>;
};

export default Notification;
