import React from "react";
import { Outlet } from "react-router-dom";
import { Layout } from "antd";
import AppHeader from "../../components/header";
import AppFooter from "../../components/footer";
import "./index.scss";

const { Header, Footer, Content } = Layout;

const LandingPage = () => {
  return (
    <Layout className="app-layout">
      <Header className="layout-header">
        <AppHeader />
      </Header>
      <Layout>
        <Content className={`flex layout-content`}>
          <Outlet />
        </Content>
        <Footer className="layout-footer">
          <AppFooter />
        </Footer>
      </Layout>
    </Layout>
  );
};

export default LandingPage;
