import { v4 as uuidv4 } from "uuid";
import useNotification from "./useNotification";
import useDao from "./useDao";
import { DB_COLLECTIONS, NOTIFICAION_TYPE } from "../constants";

const useProducts = () => {
  const { getCollection, setDocument } = useDao();
  const { setNotification } = useNotification();

  /**
   * @function sendMessage
   * @param { unknown } message: Message
   * @description Send Message
   */
  const sendMessage = (message) => {
    const updatedMessage = {
      ...message,
      key: uuidv4(),
    };

    return getMessage(updatedMessage.email).then((res) => {
      console.log("res", res);
      const openQuery = (res || []).find((f) => f.status === 0);

      if (openQuery) {
        throw Error("Query Open");
      } else {
        return setDocument(
          DB_COLLECTIONS.MESSAGES,
          updatedMessage.key,
          updatedMessage
        )
          .then(() => {
            setNotification({
              type: NOTIFICAION_TYPE.SUCCESS,
              message: "Send a Message",
              description: "Message sent successfully!",
            });
          })
          .catch((err) => {
            console.error("Error while sending message", err);

            setNotification({
              type: NOTIFICAION_TYPE.ERROR,
              message: "Send a Message",
              description: "Failed to send message",
            });
          });
      }
    });
  };

  /**
   * @function getMessage
   * @param { string } email Email
   * @description Get all units
   */
  const getMessage = (email) => {
    const filter = {
      field: "email",
      operator: "==",
      value: email,
    };
    return getCollection(DB_COLLECTIONS.MESSAGES, filter).catch((err) => {
      console.error("Error while connecting to server!", err);

      setNotification({
        type: NOTIFICAION_TYPE.ERROR,
        message: "Send Message",
        description:
          "Failed to connect to server. Please try again after sometime!",
      });
    });
  };

  return {
    sendMessage,
    getMessage,
  };
};

export default useProducts;
