import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Image from "../image";
import AppMenu from "../appMenu";
import logo from "../../assets/images/logo.png";
import { ROUTES } from "../../constants";
import "./index.scss";

const Header = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    if (pathname) {
      const key = Object.keys(ROUTES).find((f) => ROUTES[f] === pathname);

      if (key) {
        setCurrent(key);
        navigate(ROUTES[key]);
      } else {
        setCurrent(null);
        navigate(ROUTES.HOME);
      }

      document.getElementById("root").scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    }
  }, [pathname]);

  return (
    <div className="app-header flex flex--align-center">
      <div
        className="flex flex--align-center cursor-pointer"
        onClick={() => navigate("/")}
      >
        <Image width={30} src={logo} />
        <div className="app-name font--extra-bold font--primary-family font--medium m--l-10">
          AlphaZBrains
        </div>
      </div>
      <AppMenu selectedMenu={current} setSelectedMenu={setCurrent} />
    </div>
  );
};

export default Header;
